<template>
  <div class="container">
      <LoadingSpinner :loading="loading"> </LoadingSpinner>

      <div class="mt-4" style="max-width:545px">
            <h3>Z-rapport</h3>
            <div v-if="!loading">
                  <div v-if="hasMultiLocations()" class="mt-5">
                    <span class="ingredient-title">Velg avdeling</span>
                    <select class="form-select" v-model="locationId" @change="showTerminals()">
                      <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
                    </select>
                  </div>

                  <div v-if="terminals.length > 0">
                    <SelectTerminal :terminals="terminals" :terminal="selectedTerminal" @setSelectedTerminal="setSelectedTerminal"/>
                      <SelectDate ref="selectDate" :label="'Velg dato'"/>
                      <button type="button" class="bo_btn" :class="isObjectEmpty(this.zReport) ? 'mb-5' : 'mb-0'"
                              @click="showZReport"> Vis Z-rapport </button>

                      <div v-if="isObjectEmpty(zReport) && resultFetched" class="xz-report mb-5">
                        <p class="no-report-found">Fant ingen Z-rapport.</p>
                      </div>

                      <ShowZReport v-if="!isObjectEmpty(zReport)"
                                   :location="getLocation"
                                   :terminal="selectedTerminal"
                                   :zReport="zReport"
                                   :company="company"
                                   :vat="vat" />
                  </div>
                  <div v-else class="mt-4 mb-5">
                    <p>
                      Ingen kassepunkt er satt opp for denne avdelingen.
                    </p>
                  </div>
          </div>
      </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/other/LoadingSpinner";
  import SelectTerminal from "@/components/report/SelectTerminal";
  import SelectDate from "@/components/other/SelectDate";
  import ShowZReport from "@/components/report/zreport/ShowZReport";
  import ReportService from "@/services/report/ReportService";
  import LocationUtil from "@/util/LocationUtil";
  import TerminalService from "@/services/terminal/TerminalService";

  export default {
    components: {LoadingSpinner, SelectTerminal, SelectDate, ShowZReport},
    data() {
      return {
        loading: true,

        locationId: '',
        locations: [],
        merchant: '',
        company: {},
        terminals: [],
        selectedTerminal: {},
        selectedDate: new Date(),
        vat: {},
        zReport: {},
        resultFetched: false
      };
    },
    computed: {
      getLocation(){
        return LocationUtil.getLocation(this.locationId);
      },
      isXReportCountersEmpty() {
        if(this.xReportCounters.vippsPickupVat == 0 &&
            this.xReportCounters.vippsDeliveryVat == 0 &&
            this.xReportCounters.vippsDeliveryServiceVat == 0 &&
            this.xReportCounters.vippsPickupBrutto == 0 &&
            this.xReportCounters.vippsDeliveryBrutto == 0 &&
            this.xReportCounters.vippsDeliveryServiceBrutto == 0 &&
            this.xReportCounters.cardPickupVat == 0 &&
            this.xReportCounters.cardDeliveryVat == 0 &&
            this.xReportCounters.cardDeliveryServiceVat == 0 &&
            this.xReportCounters.cardPickupBrutto == 0 &&
            this.xReportCounters.cardDeliveryBrutto == 0 &&
            this.xReportCounters.cardDeliveryServiceBrutto == 0 &&
            this.xReportCounters.refundReceiptsCount == 0 &&
            this.xReportCounters.refundReceiptsTotalAmount == 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      ReportService.getDataForReportGui().then(
          (response) => {
            let dataForGui = response.data;
            //console.log("dataForGui: " +  JSON.stringify(dataForGui));
            this.locations = dataForGui.locations;
            this.company = dataForGui.company;
            this.vat = dataForGui.vat;
            this.merchant = dataForGui.merchant;

            this.locationId = this.locations.length ? this.locations[0].id : '';

            this.loading = false;

            if(this.locations.length == 1){
              this.showTerminals();
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      showTerminals() {
        this.loading = true;

        TerminalService.getTerminalsForLocation(this.locationId).then(
            (response) => {
              let filteredTerminals = [];
              for(let i = 0; i < response.data.length; i++) {
                let terminal = response.data[i];
                if(terminal.terminalType != 'KDS'){
                  filteredTerminals.push(terminal);
                }
              }
              this.terminals = filteredTerminals;
              this.selectedTerminal = this.terminals.length > 0 ? this.terminals[0] : {};

              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      setSelectedTerminal(terminal){
        this.selectedTerminal = terminal;
      },
      showZReport() {
        this.loading = true;

        let selectedDate = this.$refs.selectDate.getSelectedDate();
        ReportService.getZReport(this.selectedTerminal.terminalId, selectedDate).then(
            (response) => {
              if(response.data.cagistahospitality) {
                this.zReport= response.data.cagistahospitality;
              }else {
                this.zReport= {};
              }
              //console.log('zReport: ' +  JSON.stringify(this.zReport));
              this.loading = false;
              this.resultFetched = true;
            },
            (error) => {
              console.log(error);
            }
        );
      },
      isObjectEmpty(jsonObject){
        return JSON.stringify(jsonObject) === '{}';
      },
    }
  };
</script>
