<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <h3 class="mb-4">Ordrer</h3>

    <div class="row gx-4" ref='table'>
      <div v-for="order in orders" :key="order.id" class="col-lg-4" style="min-width: 300px" @click="goToOrderDetailPage(order.id)">
        <div class="p-3 order">
          <div v-if="hasMultiLocations()">
            <span style="font-weight: bold">{{ order.locationName }}</span>
          </div>
          <div class="bold">
            <span style="text-transform: uppercase"> {{ order.type }}</span> - <span>Ordre #{{ order.orderId }} </span>
          </div>
          <div>
            <span>Bestilt tidspunkt: {{ order.orderedTime }}</span>
          </div>
          <div>
            <span>Navn: </span> <span style="font-weight: bold; font-size: 18px"> {{ order.name }} </span>
          </div>
          <div>
            <span>Telefonnr: </span> <span style="font-weight: bold; font-size: 18px"> {{ order.mobileNumber }}</span>
          </div>

          <div class="right-align vertical-center" style="font-size: 28px; color:#ECB109;">
            <font-awesome-icon :icon="['fas', 'angle-right']" />
          </div>

          <div>
            <span>Status:</span> <span :class="order.orderStatus.toLowerCase()">{{ order.statusText }}</span>
          </div>
          <div>
            <span>Betaling: {{ order.paymentStatus }}</span>
          </div>
          <div v-if="useFunctionality() === 'POS' || useFunctionality() === 'ALL'">
            <span>{{ order.table }}</span>
          </div>
          <div>
            <span>Betjent av: {{ order.servedBy }}</span>
          </div>
          <div>
            <span>Bestilt via: {{ order.channel }}</span>
          </div>
          <div class="bold">
            <span>Totalbeløp: {{ order.toPayAmount }}</span>
          </div>
        </div>
      </div>
    </div>

    <p v-if='orders.length' id='load-more' :class='loadMoreStatus'></p>
  </div>
</template>

<!-- infinite scroll: https://mitya.uk/articles/implementing-infinite-scroll-lazy-loading-vue-3 -->
<script setup>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import OrderUtil from "@/util/OrderUtil";
import LocationUtil from "@/util/LocationUtil";
import MerchantUtil from "@/util/MerchantUtil";
import MathUtil from "@/util/MathUtil";
import {onMounted, ref} from "vue";
import { useRouter } from 'vue-router'
import authHeader from "@/services/auth/AuthHeader";

const router = useRouter()
const loading = ref(true);
const page = ref(1);
const limit = 50;
const orders = ref([]);
const table = ref(null);

const loadData = async () => {
  let basicAuth = authHeader();
  const requestOptions = {method: "GET", headers: basicAuth};
  await new Promise(res => setTimeout(res, 500));
  let user = JSON.parse(localStorage.getItem('user'));
  let url = '/api/order/listOrders' + '?merchant=' + user.merchant + '&limit=' + limit + '&page=' + page.value;
  const req = await fetch(url, requestOptions);
  const ordersArray = await req.json();

  for (let i = 0; i < ordersArray.length; i++) {
    let order = ordersArray[i];
    orders.value.push({
      'locationName': getLocationName(order.locationId),
      'id': order.id,
      'orderId' : order.orderId,
      'name': order.name,
      'mobileNumber': order.mobileNumber,
      'orderStatus': order.orderStatus,
      'statusText' : OrderUtil.getOrderStatusText(order.orderStatus),
      'paymentStatus' : OrderUtil.getPaymentStatusText(order.payments),
      'type' : OrderUtil.getOrderTypeText(order.orderType),
      'table' : order.tableName,
      'servedBy' : order.servedBy,
      'channel' : OrderUtil.getChannelText(order.channel),
      'orderedTime' : OrderUtil.getDateTimeText(order.orderedTime),
      'totalAmount' : order.totalAmount,
      'toPayAmount' : MathUtil.displayTwoDecimals(order.toPayAmount)
    });
  }
  loadMoreStatus.value = ordersArray.length < limit ? 'no-more' : 'idle';
  page.value++;
  loading.value = false;
}

//infinite scroll
const loadMoreStatus = ref('idle');
onMounted(() => {
  let scrollCb;
  // eslint-disable-next-line no-unused-vars
  window.addEventListener('scroll', scrollCb = async evt => {
    if (loadMoreStatus.value == 'idle' && table.value.getBoundingClientRect().bottom < window.innerHeight) {
      loadMoreStatus.value = 'loading';
      await loadData();
    }
  });
  scrollCb();
});

function useFunctionality(){
  return MerchantUtil.useFunctionality();
}

function hasMultiLocations(){
  return LocationUtil.hasMultiLocations();
}

function getLocationName(locationId){
  let location = LocationUtil.getLocation(locationId);
  return location.name;
}

function goToOrderDetailPage(orderDocumentId){
  router.push({ path: 'orderDetail', query: {
      orderDocumentId: orderDocumentId
    }
  });
}

</script>

<style scoped>
.order {
   border-radius: 10px;
   border: 2px solid #eaecee;
   background-color:  #eaecee;
   cursor: pointer;
}

.order:hover {
  border-radius: 10px;
  border: 2px solid #eaecee;
  background-color: dimgrey;
  color: #FFFFFF !important;
  cursor: pointer;
}

.ordered {
  color: #ECB109;
  text-transform: uppercase;
  font-weight: bold;
}

.confirmed {
  color: green;
  text-transform: uppercase;
  font-weight: bold;
}

.completed {
  /*color: black;*/
  text-transform: uppercase;
}

.vertical-center{
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

/* infinite scroll container */
#load-more {
  text-align: center;
  color: #999;
}

/* loading spinner */
#load-more.loading::before {
  content: '';
  margin-top: 8px;
  width: 35px;
  height: 35px;
  border: solid 2px gray;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

#load-more.no-more::before {
  content: '';
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
